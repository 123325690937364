/* Table styling */

#teacher-table {
    /* background-color: blueviolet;   */
    display: block;
    /* overflow-x: auto; */
    white-space: nowrap;
    border-radius: 10px;
}

#teacher-table th {
    background-color: rgb(39, 39, 39);
    color: #fffafa;
    border-left: 1px solid white;
    border-right: 1px solid white;
}

#teacher-table td {
    background-color: #fffafa;
}

#teacher-table .not-assigned {
    /* height: 170px;
    width: 200px; */
    font-weight: 900;
}

/* Mechanic for sticky scrolling */

table tr th:first-child, table td:first-child {
    position: sticky;
    left: 0;
    z-index: 10;
}

table tr th:first-child {
    z-index: 11;
}

table tr th {
    position: sticky;
    top: 0;
    z-index: 9;
}

.student-col {
    /* border: solid red 2px; */
    text-align: left;
    font-size: larger;
    padding: 10px;
    /* padding-left: 10px; 
    padding-right: 10px; */
    min-width: 200px;
    height: 200px;
}

/* Assignment Box Styling */

#teacher-table-assignment {
    height: 230px;
    width: 310px;
    display: flex;
    flex-direction: column;
    /* justify-content: flex-end; */
}

#teacher-table-assignment .audio-player {
    height: 100%;
}

#teacher-table-assignment .status-box {
    min-height: 25px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    padding-top: 3px;
    border: 2px rgb(70, 70, 70) solid;
}

/* Assignment Box Status Mechanic */

#teacher-table-assignment .INCOMPLETE {
    background-color: rgb(231, 4, 4);
    color: white;
}

#teacher-table-assignment .COMPLETE {
    background-color: rgb(33, 224, 8);
    color: black;
}

#teacher-table-assignment .GRADING {
    background-color: yellow;
    color: black;
}

#teacher-table-assignment .GRADED {
    background-color: rgb(31, 97, 158);
    color: rgb(255, 255, 255);
}

#teacher-table-assignment .SUBMITTED {
    background-color: green;
    color: white;
}

#teacher-table-assignment .slider-box {
    padding-bottom: 10px;
    height: 60px;
}

#teacher-table-assignment .slider-box>div>div {
    height: 30px;
}

#teacher-table-assignment .slider-box .label {
    padding: 0px 10px;
    width: 50px;
    text-align: center;
}

#teacher-table-assignment .slider-box .teacher-slider {
    margin-top: -2px;
    margin-left: -20px;
}